@media (max-width: 1600px) {}

@media (max-width: 1550px) {
    .error-text-box {
        padding-top: 35px;
    }

    aside {
        width: 235px;
    }



    .table-responsive thead tr th:before {
        right: 5px;
    }

}


@media (max-width: 1499px) {

    .side-menu {
        height: calc(100vh - 25vh);
    }

}


@media (max-width: 1200px) {
    .to-do-list-home ul li h3 {
        max-width: 187px;
    }

    .home-profile-schedule p {
        max-width: 402px;
    }
}

@media (max-width: 991px) {
    .to-do-list-home ul li h3 {
        max-width: 85%;
    }

    .otp-banner-img {
        display: none;
    }

    .left-banner-img {
        display: none;
    }

    .navbar-toggler {
        border: var(--bs-border-width) solid rgba(255, 255, 255, 0.514) !important;
    }

    .navbar-toggler-icon {
        filter: invert(1);
    }

    .notification-icon .dropdown-menu[data-bs-popper] {
        right: -288px !important;
    }


}

@media (max-width: 768px) {
    .home-profile-schedule p {
        max-width: 100%;
        text-align: left;
    }

    .home-welcome-txt h2 {
        font-size: 24px;
    }

    .home-welcome-txt>div {
        gap: 7px;
    }

    .home-welcome-txt {
        margin-top: 0px;
    }


    .home-profile-data>div:last-child {
        text-align: center;
    }

    .home-profile-schedule {
        flex-direction: column;
        display: flex;
        text-align: center;
        gap: 10px;
    }

    .home-profile-schedule>div {
        text-align: left;
    }

    .to-do-list-home {
        margin-top: 20px;
    }

    .nav-action-side {
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .about-box-title h2 {
        font-size: 22px;
    }

    .about-img-side img {
        margin-bottom: 10px;
    }

    .about-sec-set {
        margin-top: 20px !important;
    }

    .about-tow-sec-set {
        flex-direction: column-reverse;
    }

    .about-txt-side p {
        font-size: 16px;
    }

    .about-txt-side p {
        font-size: 16px;
    }

    .to-do-list-home ul li h3 span {
        font-size: 16px;
    }

    .songlist-selection {
        margin-bottom: 20px;
    }

    .comm-top-title-sec {
        flex-direction: column;
        gap: 10px;
        align-items: baseline;
    }

    .message-box-chatlist ul {
        height: auto;
        margin: 0;
    }

    .message-box-chatscreen {
        margin-top: 20px;
    }

    .resource-card-list {
        display: grid;
        grid-template-columns: auto;
        gap: 20px;
    }

    .resource-card {
        flex-direction: column;
        gap: 10px;
    }

    .resource-card>div {
        flex-direction: column;
        gap: 10px;
    }

    .faq-list-accro .accordion-button:not(.collapsed) {
        font-size: 16px;
    }

    .faq-list-accro .accordion-button {
        font-size: 16px;
    }

    .faq-list-accro .accordion-body {
        font-size: 16px;
    }

    .navbar-toggler-icon {
        filter: invert(1);
    }

    .navbar-toggler {
        border: none !important;

    }

    .navbar-toggler:focus {
        outline: none;
        box-shadow: none !important;
    }

    .home-profile-data>div h2 {
        font-size: 20px;
        text-align: left;
    }

    .table-responsive tbody tr td {
        white-space: nowrap;
    }

    .showing-user p {
        display: none;
    }

    .to-do-list-home ul li p {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .notification-icon .dropdown-menu[data-bs-popper] {
        top: 100%;
        right: -342% !important;
        left: auto !important;
        top: 50px;
        margin: auto;
    }

    .home-profile-schedule>div span svg {
        margin: 0px 10px 0px 0px;
    }

        .hide-on-mobile {
            display: none;
        }

}

@media (max-width: 576px) {
    .hide-export-mb button {
        display: none;
    }

    .button-on-mobile button {
        display: flex;
    }

    .button-on-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    .hide-in-mb {
        display: none;
    }

    .search-tab {
        width: 100%;
        justify-content: space-between;
    }

    .input-search {
        width: 100%;
    }

}

@media (max-width: 480px) {

    .hide-in-mobile {
        display: none;
    }

    .hide-in-web {
        display: block;
    }

    .home-profile-data {
        align-items: baseline;
        flex-direction: column;
        gap: 11px;
    }

    .home-welcome-txt h2 {
        font-size: 20px;
    }

    .comn-tabs-set .nav-link {
        padding: 10px 15px;
    }




}


@media (max-width: 375px) {

    .home-welcome-txt .white-bg {
        font-size: 13px;
        padding: 12px 16px;
    }

    .home-welcome-txt .color-bg {
        font-size: 12px;
    }

}