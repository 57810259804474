@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #f7f7f8 !important;
  font-family: "Inter", serif !important;
}

.sign-banner-part {
  display: flex;
  align-items: center;
}

.logo-side a {
  width: 235px;
  margin: auto;
  display: flex;
}

.logo-side img {
  width: 80px;
  margin: auto;
  margin-top: 20px;
}

.left-banner-img {
  background-image: url("../Images/banner-sign.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  height: 96vh;
  overflow: hidden;
  max-height: calc((9 / 8) * 90vh);
}

.logo {
  margin-left: 46px;
  margin-top: 20px;
  position: absolute;
  top: 20px;
  z-index: 1;
}

.logo img {
  width: 140px;
}

.login-box-inner-wrap {
  max-width: 550px;
  margin: auto;
  padding: 45px;
  min-height: 450px;
}

.login-box-inner-wrap h2 {
  color: #132027;
  font-family: "Inter", serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.6px;
  text-align: center;
}

.login-box-inner-wrap p {
  font-size: 15px;
  font-weight: 400;
  color: #6e6b78;
  font-family: "Inter", serif;
  text-align: center;
}

.login-box-inner-wrap form {
  margin-top: 15px;
}

.login-box-inner-wrap label {
  color: #444151;
  font-family: "Inter", serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.login-box-inner-wrap input {
  background: #fff;
  border-radius: 6px;
  height: 42px;
  font-family: "Inter", serif;
}

.login-box-inner-wrap input:focus {
  box-shadow: none;
  border-color: #d1d0d4;
}

.form-select:focus {
  border-color: #dee2e6 !important;
  outline: 0;
  box-shadow: none !important;
}

.login-box-inner-wrap input::placeholder {
  color: #acaab1;
  font-family: "Inter", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.pass-rember-line {
  display: flex;
  justify-content: end;
  align-items: center;
}

.remember-check {
  display: flex;
  align-items: center;
  gap: 10px;
}

.remember-check input {
  height: 16px;
  width: 16px;
}

.login-box-inner-wrap input {
  background: #fff;
  border-radius: 7px;
  height: 42px;
}

/* .login-box-inner-wrap label {
  color: #000;
  font-family: "Inter", serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
} */

.forgot {
  color: #132027;
  text-align: center;
  font-family: "Inter", serif;
  font-size: 15px;
  text-decoration: none;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: block;
}

.submit {
  margin-top: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: "Inter", serif;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 42px;
  text-decoration: none;
  margin: auto;
  border-radius: 7px;
  background: #d61f7f;
  box-shadow: 0px 2px 6px 0px rgba(255, 255, 255, 0.3);
  border: none !important;
}

.otp-banner-img {
  background-image: url("../Images/banner-sign.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  height: 96vh;
  overflow: hidden;
  max-height: calc((9 / 8) * 90vh);
}

.forgot-btn {
  margin-top: 30px !important;
}

.back-link-ad {
  text-align: center;
  margin-top: 20px;
}

.back-link-ad a {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  gap: 6px;
  color: #132027;
  text-decoration: none;
}

.reset-banner-img {
  background-image: url("../Images/banner-sign.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  height: 96vh;
  overflow: hidden;
  max-height: calc((9 / 8) * 90vh);
}

.error-banner-img {
  background-image: url("../Images/error-bg.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 30%;
  position: relative;
}

.error-text-box {
  max-width: 100%;
  margin: auto;
  text-align: center;
  padding-top: 90px;
}

.error-text-box h2 {
  font-size: 96px;
  font-weight: 500;
  line-height: 96px;
}

.error-text-box>div {
  /* Basic Typography/h4 */
  font-family: "Public Sans";
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  /* 158.333% */
}

.error-text-box p {
  font-size: 15px;
  font-weight: 400;
  margin-top: 11px;
  color: #6c6976;
}

.error-text-box a {
  background: #132027;
  box-shadow: 0px 2px 6px 0px rgba(255, 255, 255, 0.3);
  padding: 10px 15px;
  color: #fff;
  border-radius: 8px;
  text-decoration: none;
  display: block;
  max-width: 140px;
  margin: auto;
}

.maintenance-banner-img {
  background-image: url("../Images/under-maintenance.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 30%;
  position: relative;
}

.resend-mail p {
  text-align: center;
  margin-top: 20px;
}

.resend-mail p a {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-decoration: none;
  color: #132027;
}

.login-box-inner-wrap p span {
  font-size: 15px;
  color: #444151;
  font-weight: 400;
}

.new-otp-banner-img {
  background-image: url("../Images/banner-sign.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  height: 96vh;
  overflow: hidden;
  max-height: calc((9 / 8) * 90vh);
}

/* dashboard page css */

.main-wrap {
  height: 100vh;
  padding: 15px;
}

aside {
  background: #132027;
  width: 270px;
  height: 95vh;
  position: fixed;
  left: 15px;
  z-index: 1;
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
  border-radius: 16px;
}

.toggle-icon {
  position: fixed;
  left: 245px;
  border: 2px solid #fff;
  padding: 5px 6px;
  background: #071523;
  filter: drop-shadow(0px 6px 10px rgba(151, 151, 151, 0.47));
  width: 40px;
  height: 40px;
  top: 70px;
  display: flex;
  justify-content: center;
  z-index: 1;
  border-radius: 62px;
  align-items: center;
  cursor: pointer;
}

.toggle-icon img {
  width: 20px;
  filter: invert(1);
}

.logo-side img {
  width: 150px;
  margin: 25px 23px;
}

.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 22vh);
}

.side-menu .side-btm-space {
  margin-bottom: 5px;
}

.side-menu a.bar_active {
  color: #ffffff;
  border-radius: 6px;
  background: #fff;
  margin: 0px 10px;
  padding: 12px 15px;
}

.side-menu a {
  color: #979699;
  display: flex !important;
  font-family: "Inter", serif;
  font-size: 15px;
  padding: 10px 25px;
  font-weight: 400 !important;
  line-height: normal;
  text-decoration: none;
  align-items: center;
}

.side-menu a.bar_active {
  color: #132027;
}

a.menu-btn.bar_active path {
  fill: #132027;
}

a.menu-btn path {
  fill: #979699;
}

.side-menu a svg {
  margin-right: 13px;
  width: 22px;
}

.side-menu a p {
  margin: 0;
}

.right-side-cmn {
  width: 100%;
  position: relative;
  margin-left: auto;
  background: #fbfbfb;
  height: 100vh;
  /* padding-left: 10px; */
}

.dashboard-items {
  margin: 30px 0 10px 0px;
}

.right-top-bar {
  border-radius: var(--Border-Radius-border-radius-md, 6px);
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
  padding: 4px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}

.left-side-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-side-bar input {
  border: 0 !important;
  background-color: transparent;
  outline: none !important;
}

.left-side-bar input::placeholder {
  color: #acaab1;
  font-size: 15px;
  font-weight: 400;
}

.right-side-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.right-top-bar .user-img-top img {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.select-box {
  display: flex;
  justify-content: center;
  width: 100%;
}

.dashbox-inner-wrap select.form-select {
  border: 1px solid #b9b5ab;
  background: #fff;
  color: #000;
  font-family: "Inter", serif;
  height: 40px;
  width: 200px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;

  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
  border-radius: 0;
  background-image: url("../Images/Expand_down.svg");
  background-repeat: no-repeat;
  background-position: center right 10px;
}

.dash-inner-boxes {
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%);
  padding: 25px 22px;
  margin-top: 10px;
  border-radius: 6px;
}

.session {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
}

.left-session p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #444151;
  margin-bottom: 5px;
}

.left-session h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  color: #444151;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-session h2 span {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #28c76f;
}

span.total-users {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #6e6b78;
}

.filters {
  background: #fff;
  border-radius: 15px;
  padding: 10px 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.user-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 22px;
}

.search-tab {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.export-box p {
  margin: 0px;
  display: flex;
  gap: 9px;
  align-items: center;
}

.export-box p {
  margin: 0px;
  display: flex;
  gap: 9px;
  align-items: center;
  color: #808390;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

.export-box {
  background: #eaebed;
  /* padding: 8px 17px; */
  border-radius: 6px;
  cursor: pointer;
}

.input-search input {
  background: #fff;
  border-radius: 6px;
  min-width: 250px;
  border: 1px solid #d1d0d400 !important;
  font-size: 15px;
  height: 40px;
  font-weight: 400;
  padding-left: 40px;
}

.input-search input::placeholder {
  color: #d1d0d4;
}

input#formBasicPassword {
  box-shadow: none;
  border: 1px solid #d1d0d4;
}

.select-box {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 70px;
}

.table-responsive thead tr th {
  padding: 15px 10px;
  text-transform: capitalize;
  position: relative;
  color: var(--Black, #132027);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-bottom: 1px solid;
  white-space: nowrap;
}

.table-responsive thead {
  border-bottom: 1px solid rgba(19, 32, 39, 0.1) !important;
}

.table-border-none table,
th,
td {
  border: none;
  border-style: none !important;
  padding: 10px;
}

.table-responsive thead th {
  border-bottom: none;
  /* Ensures no extra borders from individual <th> elements */
}

.user-checkbox {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-profile img {
  height: 34px;
  width: 34px;
}

.first-user {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-profile {
  display: flex;
  gap: 10px;
  align-items: center;
}

.user-id p {
  font-size: 15px;
  color: #444151;
  font-weight: 500;
  margin: 0px;
}

.user-id span {
  font-size: 13px;
  color: #6e6b78;
  font-weight: 400;
  margin: 0px;
}

.table-responsive tbody tr td {
  padding: 15px 10px;
  vertical-align: middle;
  color: var(--Black, #132027);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.user-role p {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 400;
  margin: 0px;
  color: #444151;
}

.debit {
  font-size: 13px;
  font-weight: 500;
  line-height: 47px;
  color: #6e6b78 !important;
}

.pending p {
  color: #ff9f43;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.pending {
  background: #ffefe1;
  text-align: center;
  max-width: 90px;
  padding: 5px;
  border-radius: 6px;
}

.table-responsive td .active {
  background: #dcf6e8;
  text-align: center;
  max-width: 90px;
  padding: 5px;
  border-radius: 6px;
}

.table-responsive td .active p {
  color: #28c76f;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.action {
  display: flex;
  gap: 16px;
  align-items: center;
}

.user-role {
  line-height: 47px;
}

.showing-user p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #acaab1;
  margin: 0px;
}

.pagination-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-responsive table {
  margin: 0px;
}

.pagination-section {
  padding: 10px;
}

.pagination-block a.page-link {
  background: #efeef0;
  border-radius: 6px;
  color: #423f4f;
  font-size: 13px;
  font-weight: 400;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  line-height: 38px;
  justify-content: center;
  padding: 0px;
}

.pagination-block ul {
  margin: 0px !important;
  gap: 6px;
}

.pagination-block li.page-item a span {
  font-size: 25px;
}

.pagination-block li.page-item.active .page-link {
  background: #d61f7f;
  border: 1px solid #d61f7f;
  border-radius: 6px;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  line-height: 38px;
  justify-content: center;
}

.pagination-block .page-link:focus {
  box-shadow: none !important;
}

.pagination-block li.page-item:hover a {
  background: #e8e6fc;
  color: #132027;
}

.made-with {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  padding: 10px 0;
  margin-top: 23px;
}

.pixinvent p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0px;
  color: #6c6976;
}

.pixinvent p span {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0px;
  color: #132027;
}

.bottom-links a {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0px;
  color: #132027;
  text-decoration: none;
}

.bottom-links {
  display: flex;
  align-items: center;
  gap: 16px;
}

.set-otp input {
  width: calc(100% / 6) !important;
  border: 1px solid #d1d0d4;
  outline: none;
  height: 50px !important;
}

.set-otp>div {
  justify-content: space-between !important;
  gap: 10px;
}

.notify-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notify-head>div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notify-head>div span {
  background-color: #e8e6fc;
  padding: 10px;
  border-radius: 5px;
  color: #132027;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 10px;
}

.drop-pro-view {
  display: flex;
  gap: 10px;
  align-items: self-start;
}

.admin-name {
  text-align: left;
}

.commn-top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commn-top-header h2 {
  color: #132027;
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.logout-btn {
  background-color: #ff4c51 !important;
  color: #fff !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 3px !important;
  margin: 0px 10px !important;
  border-radius: 8px !important;
  width: 179px !important;
  margin-top: 10px !important;
}

.admin-name h3 {
  color: #444151;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin: 0px;
}

.admin-name p {
  font-size: 13px;
  font-weight: 400;
  color: #acaab1;
  line-height: 20px;
  margin: 0px;
}

.my-profile a {
  color: #444151;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  gap: 9px;
  align-items: center;
  margin-bottom: 5px;
}

.user-img-top button#dropdown-basic {
  background-color: #ffffff00 !important;
  border: none;
  padding: 0px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  gap: 0px;
}

.notification-box h5 {
  color: var(--Foundation-Grey-grey-500, #343637);
  font-family: Rubik;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2px;
}

.notification-box p {
  width: 300px;
  word-wrap: break-word;
  white-space: break-spaces;
  margin-bottom: 2px;
  color: #132027;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.notification-box span {
  color: var(--Foundation-Grey-grey-300, #6b6b6b);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.user-img-top .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  background-image: url("../Images/Expand_down.svg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  padding: 12px;
  width: 10px;
  height: 10px;
  margin-top: 0px !important;
  padding-top: 4px !important;
  display: none;
}

.user-img-top .dropdown-menu.show {
  display: block;
  right: 0;
  left: auto;
  top: 50px;
}

.notification-icon button#dropdown-basic {
  background: none;
  border: none;
  padding: 0px;
  /* width: 25px; */
  border-radius: 50%;
  /* height: 25px; */
  box-shadow: none;
}

.notification-icon .dropdown-toggle::after {
  display: none !important;
}

.notification-icon .dropdown-menu[data-bs-popper] {
  top: 100%;
  right: 0 !important;
  left: auto !important;
  margin-top: var(--bs-dropdown-spacer);
  top: 50px;
}

.notification-icon .dropdown-item:active {
  background: #f8f9fa !important;
  color: #444151 !important;
}

.user-img-top .dropdown-item:active {
  background: #f8f9fa !important;
  color: #444151 !important;
}

.notify-head p {
  color: #132027;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.user-notfic-icon img {
  width: 40px;
  height: 40px;
}

/* end dashboard */

/* my profile page */
.profile-top-box {
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
  padding: 18px 18px;
  margin-left: 10px;
  background: #fff;
}

.photo-upload {
  display: flex;
  gap: 25px;
  align-items: center;
}

.btn-up button {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background: #132027;
  padding: 10px 25px;
  border-radius: 6px;
  border: none;
  color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
}

.btn-up {
  display: flex;
  gap: 20px;
  align-items: center;
}

.reset-box p {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background: #eaebed;
  padding: 10px 15px;
  border-radius: 6px;
  color: #808390;
  margin: 0px;
}

p.image-disp {
  color: #6e6b78;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 16px;
  margin-bottom: 0px;
}

.input-profile {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.input-field-width {
  width: 50%;
}

.input-field-width input::placeholder {
  color: #acaab1;
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #d1d0d4 !important;
}

.profile-disp label {
  margin-bottom: 3px;
}

.heading-profile h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #444151;
  margin: 0px;
}

.password-req h4 {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  color: #6e6b78;
  margin: 0px;
}

.password-req p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #6e6b78;
  margin: 0px;
  margin-top: 12px;
  display: flex;
  gap: 8px;
}

.user-verification img {
  background: #132027;
  padding: 7px;
  height: 33px;
  width: 33px;
  border-radius: 6px;
}

.user-verification img:nth-child(2) {
  background: #ff4c51;
  padding: 7px;
  height: 33px;
  width: 33px;
  border-radius: 6px;
}

.cont-num {
  line-height: 47px;
  color: #444151;
}

a.menu-btn.bar_active ellipse {
  stroke: #fff;
}

/* end */

input[type="checkbox"] {
  position: relative;
  border: 2px solid #acaab1;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  border-radius: 6px;
}

input[type="checkbox"]:checked {
  background-color: #d61f7f;
  opacity: 1;
  border: 1px solid #d61f7f;
}

input[type="checkbox"]:before {
  content: "";
  position: absolute;
  right: 50%;
  top: 50%;
  width: 6px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.side-pages {
  padding: 10px 25px;
}

.side-pages p {
  margin: 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #acaab1;
}

.pass-eys img {
  position: absolute;
  top: 0px;
}

.pass-eys {
  position: relative;
}

.pass-eys img {
  position: absolute;
  top: 39px;
  right: 19px;
  cursor: pointer;
}

.file-input {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background: #132027;
  padding: 10px 25px;
  border-radius: 6px;
  border: none;
  color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
}

.comn-table-title h3 {
  color: #132027;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.comn-table-title h3 span {
  color: #979699;
}

.comn-table-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.comn-table-title button {
  border-radius: 6px;
  background: #132027;
  border: 0;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding: 8px 15px;
}

.login-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.alreadyac-txt-line p {
  color: var(--Black, #132027);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.login-link-txt {
  color: var(--Black, #132027);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.or-divider {
  position: relative;
  border-top: 1px solid #e5e5e5 !important;
  margin: 40px 0px;
}

.or-divider p {
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
  margin: auto;
  background: #fbfbfb;
  width: 40px;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.sign-social-links button {
  border-radius: 10px;
  border: 1px solid #979699;
  height: 50px;
  width: 100%;
  margin-bottom: 10px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #464646;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.border-none {
  border: 0 !important;
}

.modal-header>div {
  color: #132027;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
}

.modal-footer .secondary {
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  color: #121212;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  height: 45px;
  width: 160px;
}

.modal-footer .primary {
  border-radius: 10px;
  background: #d61f7f;
  border: 1px solid #d61f7f;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  height: 45px;
  width: 160px;
}

.modal-cmn-form-set label {
  color: var(--Black, #132027);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.modal-cmn-form-set input {
  padding: 10px 10px;
  font-family: "Inter", serif !important;
}

.modal-cmn-form-set input::placeholder {
  color: #979699;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.modal-cmn-form-set select {
  padding: 10px 10px;
  font-family: "Inter", serif !important;
  color: #979699;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  height: 48px;
}

.modal-cmn-form-set select::placeholder {
  color: #979699;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.modal-cmn-form-set textarea {
  resize: none;
  font-family: "Inter", serif !important;
}

.modal-cmn-form-set textarea::placeholder {
  color: #979699;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.td-txt-bg {
  border-radius: 4px;
  background: rgba(151, 150, 153, 0.2);
  display: inline;
  padding: 8px 20px;
  white-space: nowrap;
  width: 160px !important;
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.td-txt-theme {
  border-radius: 4px;
  background: #d61f7f;
  color: #fff;
  display: block;
  padding: 8px 20px;
  white-space: nowrap;
  width: 160px !important;
  text-align: center;
}

.user-profile a {
  text-decoration: 0;
  color: #132027;
  font-weight: 700;
}

/* chat screen ui css start */

.message-box-chatlist ul {
  list-style: none;
  padding: 0;
  height: calc(100vh - 39vh);
  overflow-x: auto;
  scrollbar-width: none;
  margin: 0;
}

.message-box-chatlist img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.message-box-chatlist ul li {
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px;
  width: 100%;
}

.message-box-chatlist ul li.active {
  border-radius: 0px;
  background: #efefef;
  padding: 10px;
}

.message-box-chatlist ul li>div {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 78% 20%;
  width: 100%;
}

.message-box-chatlist ul li>div h2 {
  color: #121212;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.message-box-chatlist ul li>div p {
  font-size: 14px;
  margin-bottom: 0;
  overflow: hidden;
  color: #979699;
  text-overflow: ellipsis;
  font-family: Inter;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.message-box-container {
  display: grid;
  grid-template-columns: 40% 58%;
  justify-content: space-between;
}

.message-box-chatlist ul li>div div span {
  color: #979699;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.message-box-chatlist ul li>div div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.message-box-chatlist ul li>div div .msg-num {
  background-color: #132027;
  color: #fff;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  line-height: normal;
  margin-left: auto;
}

.message-box-chatscreen {
  position: relative;
  background: #fff;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.chatscreen-top img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.chatscreen-top {
  border-radius: 0px;
  background: #ffffff00;
  padding: 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid rgba(19, 32, 39, 0.1);
}

.chatscreen-top h3 {
  color: #121212;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.chatscreen-middle {
  height: calc(100vh - 35vh);
  margin: 10px 0px;
  scrollbar-width: none;
  overflow: auto;
  padding: 10px;
}

.chatscreen-bottom {
  display: grid;
  /* grid-template-columns: 90% 8%; */
  /* justify-content: space-between; */
  grid-template-columns: 82% 15%;
  gap: 3%;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.chatscreen-bottom input {
  background: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
}

.chatscreen-bottom textarea {
  background: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  height: 50px;
}

.chatscreen-bottom button {
  border: 0;
  width: 50px;
  height: 100%;
  border-radius: 6px;
  background: #d61f7f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatscreen-bottom input:focus-visible {
  outline: none;
}

.chatscreen-bottom button svg {
  width: 20px;
  height: 20px;
}

.chat-right-side {
  width: calc(100% - 50%);
  margin-left: auto;
  text-align: end;
  margin-bottom: 12px;
}

.chat-right-side p {
  border-radius: 16px 0px 16px 16px;
  padding: 10px 20px;
  background: #202020;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
  display: inline-block;
}

.chat-right-side span {
  color: #464646;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  display: block;
}

.chat-left-side {
  width: calc(100% - 50%);
  margin-right: auto;
  margin-bottom: 12px;
}

.chat-left-side p {
  border-radius: 0px 16px 16px 16px;
  background: rgba(104, 104, 104, 0.12);
  padding: 10px 20px;
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
  display: inline-block;
}

.chat-left-side span {
  color: #464646;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  display: block;
}

/* chat screen ui css End */

.detail-view-card {
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%);
  border-radius: 6px;
  padding: 10px;
  display: grid;
  grid-template-columns: 15% auto auto auto auto;
  align-items: center;
}

.detail-pro-img img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.detail-pro-name h2 {
  color: #132027;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
}

.detail-pro-name p {
  color: #132027;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.detail-pro-edition p {
  color: #979699;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.detail-pro-edition p span {
  color: #132027;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #ddd;
}

.detail-pro-name.event-details-text p {
  color: #979699;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  width: 270px;
}

.recommended-w-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recommended-w-toggle input {
  background-repeat: no-repeat;
  width: 40px !important;
}

.recommended-w-toggle input::before {
  display: none;
}

.recommended-w-toggle input:focus {
  background-repeat: no-repeat;
  box-shadow: none !important;
  border-color: #13202744;
}

.add-administrator {
  padding: 20px;
}

.add-administrator h3 {
  color: #132027;
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 30px;
}

.add-administrator-action .secondary {
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  color: #121212;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  height: 45px;
  width: 160px;
}

.add-administrator-action .primary {
  border-radius: 10px;
  background: var(--Black, #132027);
  border: 1px solid #132027;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  height: 45px;
  width: 160px;
}

.add-administrator-action {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.messages-list {
  padding: 20px;
}

.messages-list {
  padding: 20px;
}

.messages-search {
  position: relative;
}

.messages-search svg {
  position: absolute;
  top: 12px;
  left: 12px;
  height: 20px;
  width: 20px;
}

.messages-search input {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 45px;
  padding: 10px;
  width: 100%;
  padding-left: 40px;
}

.messages-search input:focus {
  outline: none;
}

.messages-search input::placeholder {
  color: #9f9ea1;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.messages-search .clear-icon {
  position: absolute;
  top: 7px;
  right: 12px;
  font-size: 18px;
  color: #9f9ea1;
  cursor: pointer;
  transition: color 0.2s ease;
}

.messages-search .clear-icon:hover {
  color: #000;
}

.no-chat-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  /* Ensure full height usage for centering */
  width: 100%;
  color: #9f9ea1;
  font-family: Inter, sans-serif;
  font-size: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin: 0;
  /* Avoid extra margins when centering */
}

.chatscreen-middle.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.messages-tabs {
  border-radius: 10px;
  background: #e8e9ea;
  margin-top: 15px;
  padding: 8px !important;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px !important;
  justify-content: space-between;
}

.messages-tabs .nav-link.active {
  background-color: #ffffff !important;
  color: var(--Black, #132027) !important;
  font-family: Inter;
  font-size: 14px !important;
  font-weight: 500;
  line-height: normal;
}

.messages-tabs .nav-link {
  color: #464646 !important;
  font-family: Inter;
  font-size: 14px !important;
  font-weight: 500;
  line-height: normal;
}

.message-box-input {
  display: grid;
  /* grid-template-columns: 90% 8%; */
  /* justify-content: space-between; */
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

/* rajinder new css start here  */

.comm-header-nav a.navbar-brand img {
  height: 80px;
}

.comm-header-nav {
  border-radius: 16px;
  background: #132027;
  padding: 0px 20px;
}

.comm-header-nav .navbar-nav {
  gap: 10px;
}

.comm-header-nav .navbar-nav a {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400 !important;
  border-radius: 12px;
  line-height: normal;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.comm-header-nav .navbar-nav .nav-link:hover {
  color: var(--bs-navbar-active-color);
  border-radius: 12px;
  background: #3b5a6a;
  color: #fff !important;
}

.comm-header-nav .navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
  border-radius: 12px;
  background: #3b5a6a;
  color: #fff !important;
  padding-left: 20px;
  padding-right: 20px;
}

.nav-action-side {
  display: flex;
  gap: 15px;
  align-items: center;
}

.nav-action-side button {
  border: 0;
  padding: 0;
  background-color: transparent;
}

.nav-action-side img {
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.home-welcome-txt h2 {
  color: #fff;
  font-family: Inter;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
}

.home-welcome-txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 20px;
  flex-wrap: wrap;
}

.home-welcome-txt>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.home-welcome-txt .color-bg {
  border-radius: 12px;
  background: #d61f7f;
  border: 0;
  color: #ffffff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 12px 16px;
}

.home-welcome-txt .white-bg {
  border-radius: 12px;
  background: #fff;
  border: 0;
  color: #d61f7f;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 12px 16px;
  border: 0.5px solid #d61f7f;
}

.home-profile {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 20px;
}

.home-profile-data .profile-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.home-profile-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-profile-data>div:last-child {
  text-align: end;
}

.home-profile-data>div img {
  width: 80px;
  height: 80px;
  gap: 0px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.home-profile-data>div p {
  color: #979699;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}

.home-profile-data>div h2 {
  color: #132027;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.home-profile-schedule {
  display: grid;
  grid-template-columns: 60% 30%;
  justify-content: space-between;
  margin: 20px 0px;
}

.home-profile-schedule p {
  color: #979699;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.home-profile-schedule>div {
  text-align: end;
}

.home-profile-schedule>div span:first-child {
  margin-bottom: 10px;
}

.home-profile-schedule>div span {
  color: #132027;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.home-profile-schedule>div span svg {
  margin: 0px 10px;
}

.to-do-list-home {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 20px;
}

.to-do-list-home h2 {
  color: #132027;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}

.to-do-list-home ul {
  padding: 0;
  list-style: none;
}

.to-do-list-home ul li {
  margin-bottom: 20px;
}

.to-do-list-home ul li h3 {
  color: #132027;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  max-width: 312px;
}

.to-do-list-home ul li h3 span {
  font-size: 18px;
  font-weight: 700;
  color: #d61f7f;
  text-decoration: underline;
}

.to-do-list-home ul li p {
  color: #979699;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}

.to-do-list-home ul li>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.about-box-sec {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 20px;
}

.about-box-title p {
  color: #464646;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.about-box-title h2 {
  color: #132027;
  font-family: Inter;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
}

.about-img-side img {
  width: 100%;
  height: 400px;
  object-fit: contain;
  border-radius: 10px;
  background: #f1f0ed;
}

.about-txt-side h3 {
  color: #132027;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
}

.about-txt-side p {
  color: #979699;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.about-txt-side ul {
  display: flex;
  list-style: none;
  padding: 0;
  gap: 10px;
  margin: 0;
}

.comm-top-title-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.comm-top-title-sec h2 {
  color: #132027;
  font-family: Inter;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.comm-top-title-sec>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.songlist-selection {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 20px;
}

.songlist-selection h4 {
  color: #132027;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
}

.songlist-selection p {
  color: #979699;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.songlist-selection ul {
  list-style: none;
  text-align: center;
  padding: 0;
  margin-bottom: 0;
}

.songlist-selection ul li.active {
  background-color: #d61f7f;
  color: #ffffff;
  border: 1px solid;
}

.songlist-selection ul li {
  color: #d61f7f;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.no-data-found {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-found button {
  border-radius: 12px;
  background: #d61f7f;
  border: 0;
  color: #ffffff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 10px 30px;
}

.tab-with-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px;
}

.comn-tabs-set .nav-link.active {
  color: var(--bs-nav-pills-link-active-color);
  background-color: #d61f7f !important;
  color: #fff !important;
}

.comn-tabs-set {
  display: flex;
  gap: 10px;
}

.nav-pills .nav-link.active:hover {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
  color: #fff !important;
}

.nav-pills .nav-link.active:focus {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
  color: #fff !important;
}

.comn-tabs-set .nav-link.active svg path {
  stroke: #fff;
}

.comn-tabs-set .nav-link.active svg ellipse {
  stroke: #fff;
}

.comn-tabs-set .nav-link.active svg circle {
  stroke: #fff;
}

.comn-tabs-set .nav-link.active svg rect {
  stroke: #fff;
}

.comn-tabs-set .nav-link:focus,
.nav-link:hover {
  color: #2f2b3de5 !important;
}

.comn-tabs-set .nav-link {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #d61f7f !important;
  text-align: center;
  font-family: "Public Sans";
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  background: #fff;
  height: 40px;
  padding: 10px 30px;
}

.commn-card-box {
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
  padding: 18px 18px;
  background: #fff;
}

.export-box button {
  color: rgb(255, 255, 255);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-radius: 12px;
  background: rgb(214, 31, 127);
  border-width: 0px;
  padding: 12px 35px;
}

.input-search {
  position: relative;
}

.input-search {
  position: relative;
}

.input-search svg {
  position: absolute;
  top: 12px;
  left: 12px;
}

.resource-card button {
  border-radius: 12px;
  background: #d61f7f;
  border: 0;
  color: #ffffff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  width: 140px;
  padding: 12px 16px;
}

.resource-card-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.resource-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #97969933;
  padding: 40px;
  border-radius: 15px;
}

.resource-card>div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.resource-card>div span {
  background: #d9d9d9;
  width: 80px !important;
  height: 80px !important;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.resource-card>div p {
  color: #132027;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.faq-list-accro {
  margin-bottom: 50px;
}

.faq-list-accro button {
  border-radius: 10px !important;
  border: 0 !important;
  background: #eaeaeb;
  margin-bottom: 10px;
}

.faq-list-accro .accordion-item {
  border: 0 !important;
}

.faq-list-accro {
  margin-bottom: 50px;
}

.faq-list-accro button:focus:not(:focus-visible) {
  outline: 0;
  background: #eaeaeb !important;
  box-shadow: none !important;
}

.faq-list-accro .accordion-button {
  color: #000;
  font-family: "Inter", serif !important;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
}

.faq-list-accro .accordion-button:not(.collapsed) {
  outline: 0;
  background: #eaeaeb !important;
  color: #000;
  font-family: "Inter", serif !important;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
}

.please-note-txt {
  color: #979699;
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}

.pop-small-txt {
  color: #464646;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.side-btn-svg.active svg circle {
  fill: #d9d9d9;
}

.side-btn-svg.active svg path {
  stroke: #fff;
}

.side-btn-svg-two.active svg circle {
  fill: #d9d9d9;
}

.side-btn-svg-two.active svg path {
  fill: #fff;
}

.notification-icon .dropdown-toggle.show svg circle {
  fill: #d9d9d9;
}

.notification-icon .dropdown-toggle.show svg path {
  fill: #d9d9d9;
}

.about-sec-set {
  align-items: center;
  margin-top: 30px !important;
}

.about-tow-sec-set {
  align-items: center;
  margin-top: 30px !important;
}

.loader-style {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #ffffff47;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 9;
  flex-direction: column;
}

.no-custom-data {
  position: relative;
  height: 30vh;
  width: 100%;
  margin: auto;
}

.no-custom-data .no-data-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.register-box-page {
  background-color: #fff;
  border-radius: 20px;
}

.regi-checkbox {
  min-width: 22px !important;
}


.date-picker-custom span {
  position: absolute;
  top: 34px;
  left: 10px;
  background: #fff;
  padding: 5px;
  color: #acaab1;
  font-family: "Inter", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}


.date-picker-custom span.active {
  display: none;
}

.radio-group {
  margin-bottom: 1rem;
}

.radio-option {
  display: flex;
  align-items: center;
}


.radio-option div {
  margin-left: 0px;
  color: #6e6b78;
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.radio-group {
  margin-bottom: 1rem;
}

.radio-option {
  display: flex;
  align-items: center;
}

.custom-radio {
  margin-right: 10px;
  accent-color: #d61f7f;
  width: 20px;
  height: 20px;
  transform: scale(0.8);
}

.radio-option input.custom-radio {
  height: 22px;
}

.button-on-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.button-on-mobile button {
  display: none;
}

.hide-in-web {
  display: none;
}

.login-logo img {
  width: 100%;
  max-width: 280px;
}